<template>
  <div>
    <div class="main-content">
      <div class="main-manager">
        <el-input
          v-model="goodsPages.goods_name"
          placeholder="商品搜索（名称）"
          class="search-box"
          @input="changeGoodsList"
          @keyup.enter.native="searchGoodList"
        ></el-input>
        <el-button type="success" class="search-btn" @click="searchGoodList"
          >搜索</el-button
        >
        <el-button
          type="primary"
          class="upload-goods"
          @click="handleClick_GoodsAdd"
          >上传商品</el-button
        >
        <div class="main-goodsOptions">
          <el-button
            type="primary"
            class="upload-goods"
            @click="handleClick_GoodsStock"
            >仓库商品</el-button
          >
          <el-button
            type="primary"
            class="upload-goods"
            @click="handleClick_Goodsrecycle"
            >商品回收站</el-button
          >
        </div>
      </div>
      <div class="content">
        <el-table
          :data="infoList"
          style="width: 100%; margin-top: 5px"
          height="1%"
          class="customTable"
        >
          <el-table-column
            prop="img_url"
            label="主图"
            align="center"
            style="width:100%"
            height="1%"
          >
            <template slot-scope="scope">
              <img class="goods-pic" :src="scope.row.img_url" alt="" />
            </template>
          </el-table-column>
          <el-table-column prop="goods_name" label="商品名称" align="center">
          </el-table-column>
          <el-table-column prop="category_id" label="类别" align="center">
          </el-table-column>
          <el-table-column prop="stock" label="库存" align="center">
          </el-table-column>
          <el-table-column prop="price" label="价格" align="center">
          </el-table-column>
          <el-table-column prop="created_at" label="创建时间" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template align="center" slot-scope="scope">
              <el-button
                
                size="small"
                @click="handleClick_GoodsUpdate(scope.row)"
                v-if="scope.row.is_put === 0"
                style="margin-right:5px"
                >编辑</el-button
              >

              <el-button
                slot="reference"
                type="danger"
                size="small"
                style="margin-right:5px"
                @click="handleClick_GoodsDel(scope.row)"
                v-if="scope.row.is_put === 0"
                >删除</el-button
              >

              <el-button
                type="warning"
                size="small"
                @click="
                  handleClick_goodsPut(scope.row, 1, '确定要出售此商品？')
                "
                v-if="scope.row.is_put === 0"
                >上架</el-button
              >
              <el-button
                type="text"
                size="small"
                style="color:orange;font-size:14px"
                @click="
                  handleClick_goodsPut(scope.row, 2, '确定要将此商品下架吗？')
                "
                v-if="scope.row.is_put === 1"
                >下架</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="pages-center"
          @current-change="handleCurrentChange"
          :current-page.sync="goodsPages.currentPageNum"
          :page-size="goodsPages.eachPageNum"
          layout="prev, pager, next, jumper"
          :total="goodsPages.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  goods_goodsList,
  goods_delgoods,
  goods_putGoods,
  goods_delExchangeGoods,
} from "@/config/apis";
export default {
  data() {
    return {
      infoList: [],
      goodsPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
        goods_name: "",
      },
      lock: false,
    };
  },

  mounted() {
    this.getGoodsList();
  },

  methods: {
    handleClick_GoodsAdd() {
      this.$router.push({ path: "/school/storeManage/goodsManage/add" });
    },
    handleClick_GoodsStock() {
      this.$router.push({ path: "/school/storeManage/goodsManage/stock" });
    },
    handleClick_Goodsrecycle() {
      this.$router.push({ path: "/school/storeManage/goodsManage/recycle" });
    },
    getGoodsList() {
      let params = {
        paging: "1",
        pageSize: this.goodsPages.eachPageNum,
        page: this.goodsPages.currentPageNum,
        goods_name: this.goodsPages.goods_name,
        is_put: "1",
      };
      goods_goodsList(params).then((res) => {
        this.infoList = res.data.list;
        this.goodsPages.total = res.data.total;
      });
    },
    handleClick_GoodsUpdate(row) {
      this.$router.push({
        path: "/school/storeManage/goodsManage/detail",
        query: {
          id: row.goods_id,
        },
      });
    },
    handleClick_GoodsDel(row) {
      let params = {
        goods_id: row.goods_id,
      };
      this.$confirm("这是一段内容确定删除吗？", "提示消息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        goods_delgoods(params)
          .then((res) => {
            this.$message.success(res.msg);
            this.getGoodsList();
          })
          .catch((err) => {});
      });
    },
    handleCurrentChange(val) {
      this.goodsPages.currentPageNum = val;
      this.getGoodsList();
    },
    searchGoodList() {
      if (this.lock) return false;
      this.lock = true;
      setTimeout(() => {
        this.getGoodsList();
        this.lock = false;
      }, 1000);
    },
    changeGoodsList(e) {
      if (e.length === 0) {
        this.getGoodsList();
      }
    },
    handleClick_goodsPut(row, typeNum, text) {
      let params = {
        goods_id: row.goods_id,
        type: typeNum,
      };
      this.$confirm(text, "提示消息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        goods_putGoods(params)
          .then((res) => {
            this.$message.success(res.msg);
            this.getGoodsList();
          })
          .catch((err) => {});
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  margin: 20px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  .main-manager {
    .search-box {
      width: 260px;
      margin: 20px 0 0 20px;
    }
    .search-btn {
      margin-left: 10px;
    }
    .main-goodsOptions {
      margin: 20px 20px 0 20px;
      float: right;
    }
  }
}
.content {
  flex: 1;
  height: 1%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.goods-pic {
  min-width: 100px;
  height: 100px;
}
</style>
